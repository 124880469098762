export const ActionTypes = {

    GET_SERIVCE_EXEL_DATA_REQUEST: 'GET_SERIVCE_EXEL_DATA_REQUEST',
    GET_SERIVCE_EXEL_DATA_LIST_SUCESS: 'GET_SERIVCE_EXEL_DATA_LIST_SUCESS',
    GET_SERIVCE_EXEL_DATA_LIST_FAIL: 'GET_SERIVCE_EXEL_DATA_LIST_FAIL',
    
    GET_BLOG_EXEL_DATA_REQUEST: 'GET_BLOG_EXEL_DATA_REQUEST',
    GET_BLOG_EXEL_DATA_LIST_SUCESS: 'GET_BLOG_EXEL_DATA_LIST_SUCESS',
    GET_BLOG_EXEL_DATA_LIST_FAIL: 'GET_BLOG_EXEL_DATA_LIST_FAIL',


};
// BASE URLS
export const SERVICES_BASE_URL = "https://docs.google.com/spreadsheets/d/e/2PACX-1vTOM2TIoVjpn2uxYndkBSxIF1051NkZa9CABn4eNRA8dQanjs1603WLoq7-W0IsGyFmG8KiWS9Vg1RP/pub?output=csv";
export const BLOGS_BASE_URL = "https://docs.google.com/spreadsheets/d/e/2PACX-1vQoHw-Ea9lbL4wpLOU4WjhWY0dXCeIjjBIBn71Y9ypTzIRKLuwZjk29Ef0LHCuPOqgVoSVSB2z_MyuO/pub?output=csv";

